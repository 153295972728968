body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.nav-link{
  font-size: 16px !important;
  height: 3rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-link.active{
  background-color: #3565ae!important;
  color: white !important;
}

.nav.nav-tabs{
  width: 15rem !important;
}

.header-img{
  height: 6rem;
  background-color: red;
  background-image: url("../public/assets/images/apmelogo22.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}


.text-primary{
  color: #3565ae !important;
}

.bg-primary{
  background-color: #3565ae !important;
}
.btn-primary{
  background-color: #3565ae !important;
  border: 2px solid  #3565ae !important ;
}


.text-success{
  color: #6ab42d !important;
}

.bg-success{
  background-color: #6ab42d !important;
}

.btn-success{
  background-color: #6ab42d !important;
  color: white !important;
  border: 2px solid  #6ab42d !important ;
}


.bg-success-alt{
  background-color: #6ab42d !important;
}

.container-mt{
  padding-top: 15vh !important;
}

.story .img-story{

  height: 60vh;
  width: 100%;
}

@media (max-width: 767.98px) {
  .story small{
font-size: 1.1em !important;
  }
    .story .img-story{

      height: 20vh;
      width: 100vw;
      background-size: contain !important;
    }
  }

.story .fs-big{
  font-size: 9em;
}

.scroll  .select-lang {
  color: black !important;
}

.bg-soft{
  background-color: rgba(245, 245, 245, 0.95);
}




.card-criteria .content{
  height:100% ;
}
.criteria-img{
  width: 10vh;
  height: 10vh;
}
.criteria .criteria-img-title{
  width: 15vh;
  height: 15vh;
}

.hover-text-none a:hover{

  color: #6ab42d !important;
}